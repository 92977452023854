<template>
<div v-for="item in tuiguang" :key="item" >
    <div class="banner wfl" v-for="list in item.banner" :key="list">
    	<img :src="list.banner1" />
    	<img :src="list.banner2" />
    	<img :src="list.banner3" />
    	<img :src="list.banner4" />
    	<img :src="list.banner5" />
    </div>


     <div class="top_block"  v-for="list in item.biaoti" :key="list">
    	<div class="top wfl" >
    		<span> {{ list.biaoti1 }}</span>
    		<div class="t_lan wfl">
    			<div class="bai">
    			<p>
    {{ list.bai1 }} <br>
    {{ list.bai2 }} <br>
    {{ list.bai3 }} <br>
    <b>{{ list.bai4 }}</b>
    			</p>
                </div>
    			<div class="t_b">
    				<img :src="list.tb_pic" />
    				<p>{{ list.tb_con1 }} <b>{{ list.tb_con2 }}</b><br>
    {{ list.tb_con3 }}</p>
    			</div>
    		</div>
    	</div>
    	<div class="t_img wfl">
    		<img :src="list.t_img1" />
    		<img :src="list.t_img2" />
    		<img :src="list.t_img3" />
    		<img :src="list.t_img4" />
    		<img :src="list.t_img5" />
    		<img :src="list.t_img6" />
    		<img :src="list.t_img7" />
    	</div>
    </div>
    


    <div class="con1_block"  v-for="list in item.con1_block" :key="list">
    	<img :src="list.con1_block1" />
    	<img :src="list.con1_block2" />
    	<img :src="list.con1_block3" />
    	<img :src="list.con1_block4" />
    	<img :src="list.con1_block5" />
    </div>


    <div class="bar wfl"  v-for="list in item.bar" :key="list">
    	<img :src="list.bar_img" class="bar_img"/>
         <div class="top_block"> 
    		<div class="bb_img">
    			<div class="b1">
    				<p><b>{{ list.b1_1 }}</b>{{ list.b1_2 }}<br><i>{{ list.b1_3 }}</i></p>
    				<span>
    					<em>{{ list.b1_4 }}</em>
    					<i>{{ list.b1_5 }}</i>
    				</span>
    			</div>
    			<div class="b2">
    				<div class="b2_block">
    					<div class="b2_tp">
    						<img :src="list.b2_img"  />  
    						<p>{{ list.b2_1 }}</p>
    						<b>{{ list.b2_2 }}</b>
    					</div>
    					<div class="b2_wenzi">
    						<p>{{ list.b2_3}}<br>
   {{ list.b2_4}}<br>
    {{ list.b2_5 }}<b>{{ list.b2_6}}</b>{{ list.b2_7}}<br>
    {{ list.b2_8 }}              <i> {{ list.b2_9 }}</i></p>
    					</div>
    				</div>
    			</div>
    		</div>
    	</div>
    </div>

     <div class="top_block" v-for="list in item.top_block" :key="list">
    	<div class="sb_jiaoshou wfl">
    		<div class="sb_block">
    			<div class="sb_top wfl">
    				<span>
    					<p>{{ list.top_block_con1 }}  </p>
    				</span>
    			</div>
    			<div class="shamiaoshabi wfl">
    				<p>
    					{{ list.top_block_con2 }}<br>
    {{ list.top_block_con3 }}<b>{{ list.top_block_con4}}</b>

    				</p>
    			</div>
    			<div class="jiaoshou wfl">
    				<p>{{ list.top_block_con5}}</p>
    				<span>
    					<img :src="list.top_block_img1" />
    					<i>{{ list.top_block_con6 }}</i>
    				</span>
    			</div>
    			<div class="jj wfl">
    				<p>{{ list.top_block_con7 }}<b>{{ list.top_block_con8}}</b></p>
    				<div class="j_img wfl">
    					<img :src="list.top_block_img2" />
    					<img :src="list.top_block_img3" />
    					<img :src="list.top_block_img4" />
    					<img :src="list.top_block_img5" />
    				</div>
    				<span>
    					{{ list.top_block_con9 }}
    				</span>
    				<div class="j_img2 wfl">
    					<img :src="list.top_block_img6" />
    					<img :src="list.top_block_img7" />
    					<img :src="list.top_block_img8" />
    					<img :src="list.top_block_img9" />
    				</div>
    			</div>
    		</div>
    	</div>
      </div>

         <div class="con1_block" v-for="list in item.con1_block" :key="list">
    	   <div class="smsb wfl">
    		<div class="jb_1">
    			<h6>{{ list.con1_block_con1}}</h6>
    			<div class="jb_bu">
    				<h2>{{ list.con1_block_con2 }}</h2>
    				<div class="zhengwen">
    					<span>
    						<img :src="list.con1_block_img1" />
    						<p>{{ list.con1_block_con3 }}</p>
    					</span>
    					<p>{{ list.con1_block_con3 }}<br><br>

    {{ list.con1_block_con5 }}<b>{{ list.con1_block_con6}}</b><br><br>

    {{ list.con1_block_con7}}<b>{{ list.con1_block_con8}}</b>{{ list.con1_block_con9 }}                                                                               <i> {{ list.con1_block_con10 }} </i></p>
    				</div>
				
    			</div>
    		</div>
    	 </div>
    	 <div class="jb_d">
    		<img :src="list.con1_block_img2" />
    	 </div>
       </div>


        <div class="top_block" v-for="list in item.topblock" :key="list">
    	<div class="jb3_block wfl">
    		<div class="jb3">
    			<h6><b>{{ list.topblock_con1 }}</b>{{ list.topblock_con2 }}</h6>
    			<h2>{{ list.topblock_con3 }}</h2>
    			<div class="jb_lan wfl">
    			<img :src="list.topblock_img1" class="web"/>	 
    			<img :src="list.topblock_img2" class="wap"/>	 
    				<span>{{ list.topblock_con4}}</span>
    			</div>
    			<p><i>{{ list.topblock_con5 }}</i>
    {{ list.topblock_con6 }}<em>{{ list.topblock_con7 }}</em><br>
    <b></b>
    {{ list.topblock_con8}}<br>
    <b></b>
    {{ list.topblock_con9 }}</p>
    		</div>
    		<div class="b3">
    				<div class="b2_block">
    					<div class="b2_tp">
    						<img :src="list.topblock_img3" />
    						<i>{{ list.topblock_con10 }}</i>
    						<p>{{ list.topblock_con11 }}</p>
    						<b>{{ list.topblock_con12 }}</b>
    					</div>
    					<div class="b2_wenzi">
    						<p>{{ list.topblock_con13 }}<br><br>

                            {{ list.topblock_con14 }}<br><br>

   {{ list.topblock_con15}}<br><br>

    {{ list.topblock_con16 }}<b> {{ list.topblock_con17 }}</b><br><br>

    {{ list.topblock_con18 }}           <i>{{ list.topblock_con19 }}</i></p>
    					</div>
    				</div>
    			</div>
    	</div>
    	<div class="sb_jiaoshou_2 wfl">
    		<div class="sb_block">
    			<div class="sb_top wfl">
    				<span>
    					<p>{{ list.topblock_con20 }}<br><b>{{ list.topblock_con21 }}</b>
    </p>
    				</span>
    			</div>
    			<div class="jiaoshou2 wfl">
    				<p>{{ list.topblock_con22 }}</p>
    			</div>
    			<div class="shamiaoshabi_2 wfl">
    				<p>
    					{{ list.topblock_con23 }}<br>
    {{ list.topblock_con24 }}<br>
    <b>{{ list.topblock_con25}}</b>

    				</p>
    			</div>
		
    			<div class="jj_2 wfl">
    				<div class="j_img wfl">
    					<img :src="list.topblock_img4" />
    					<img :src="list.topblock_img5" />
    					<img :src="list.topblock_img6" />
    					<img :src="list.topblock_img7" />
    					<img :src="list.topblock_img8" />
    				</div>
    			</div>
    		</div>
    	</div>
     </div>







     <div class="block_1216"   v-for="list in item.block_1216" :key="list">
    	<div class="block_tupian wfl">
    	 
        <img :src="list.block_1216_img1" class="f1"/>
    	<img :src="list.block_1216_img2" />
    	<img :src="list.block_1216_img3" />
    	<img :src="list.block_1216_img4" />
    	<img :src="list.block_1216_img5" />
    	<img :src="list.block_1216_img6" />
    	<img :src="list.block_1216_img7" />
    	<img :src="list.block_1216_img8" />
    	<img :src="list.block_1216_img9" />
        <img :src="list.block_1216_img10" class="f19" />
     
    	</div>
    </div>


    <div class="top_block"  v-for="list in item.anli" :key="list">
    	<div class="anli">
    		<img :src="list.anli_img1" />
    		<img :src="list.anli_img2" />
    		<img :src="list.anli_img3" />
    		<img :src="list.anli_img4" />
    		<img :src="list.anli_img5" />
    		<img :src="list.anli_img6" />
    		<img :src="list.anli_img7" />
    		<img :src="list.anli_img8" />
    		<img :src="list.anli_img9" />
    		<img :src="list.anli_img10" />
    	</div>
    </div>
    <div class="block_bar wfl" v-for="list in item.wfl" :key="list">
    	 <img :src="list.wfl_img1" class="web"/>
    	 <img :src="list.wfl_img2" class="wap"/>
    </div>
    <div class="top_block" v-for="list in item.jb4_block" :key="list">
    	<div class="jb4_block wfl">
    		<div class="jb4_img">
    			<img :src="list.jb4_block_img0" />
    		</div>
    		<div class="jb4 wfl">
    			<p>{{ list.jb4_block_con }}</p>
    			<span>
    				<img :src="list.jb4_block_img1" />
    				<img :src="list.jb4_block_img2" />
    			    <img :src="list.jb4_block_img3" />
    				<img :src="list.jb4_block_img4" />
    				<img :src="list.jb4_block_img5" />
    				<img :src="list.jb4_block_img6" />
    				<img :src="list.jb4_block_img7" />
    				<img :src="list.jb4_block_img8" />
    				<img :src="list.jb4_block_img9" />
    				<img :src="list.jb4_block_img10" />
    			</span>
    		</div>
    	</div>
    </div>
    <div class="con1_block" v-for="list in item.cons" :key="list">
    	<img :src="list.cons_img1" />
    	<img :src="list.cons_img2" />
    	<img :src="list.cons_img3" />
    	<img :src="list.cons_img4" />
    	<img :src="list.cons_img5" />
    	<img :src="list.cons_img6" />
    	<img :src="list.cons_img7" />
    	<img :src="list.cons_img8" />
    	<img :src="list.cons_img9" />
    	<img :src="list.cons_img10" />
    </div>
    <div class="top_block"  v-for="list in item.zuihou" :key="list">
    	<div class="t_bottom">
    		<img :src="list.zuihou_img1" />
    		<span>
    			<p>{{ list.zuihou_con1 }}<a :href="'tel:' +  list.zuihou_con2 ">{{ list.zuihou_con2 }}</a>{{ list.zuihou_con3 }}</p>
    			<em>{{ list.zuihou_con4 }}</em>
    		</span>
    		<i></i>
    		<b>{{ list.zuihou_con5 }}<br>
    {{ list.zuihou_con6 }}</b>
    	</div>
    </div>



</div>    
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            tuiguang: null,
        }
    },
    mounted() {
        this.add();
    },
    methods: {
        async add() {
            axios.get('https://api.bf839.com/ad2.json').then((response) => {
                if (response) {
                    this.tuiguang = response.data.data.tuiguang;
                    console.log('数据获取成功');
                }
                else (
                    alert('数据获取失败')
                )
            }
            )
        }
    },

}
</script>

 